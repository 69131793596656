import portfolio1 from "../images/portfolio1.png";
import portfolio2 from "../images/portfolio2.png";
import portfolio3 from "../images/portfolio3.png";
import portfolio4 from "../images/portfolio4.png";
import portfolio5 from "../images/portfolio5.png";
import portfolio6 from "../images/portfolio6.png";
import portfolio7 from "../images/portfolio7.png";
import portfolio8 from "../images/portfolio8.png";

export const PortfolioData = [
    {
        id : 1,
        img: portfolio1,
        name : "Wedding of Alexa & Robert",
        slug : "wedding-of-alexa-&-robert",
        sub : "Wedding, Wedding Destination"
    },
    {
        id : 2,
        img: portfolio2,
        name : "Anna Jay & Cris Jorden Wedding Photographers",
        slug : "anna-jay-&-cris-jorden-wedding-photographers",
        sub : "Photography, Wedding Photography"
    },
    {
        id : 3,
        img: portfolio3,
        name : "Aliana & David Wedding Cake",
        slug : "aliana-&-david-wedding-cake",
        sub : "Wedding, Decoration"
    },
    {
        id : 4,
        img: portfolio4,
        name : "Bridal Jewellery",
        slug : "bridal-jewellery",
        sub : "Jewellery, Bridal Wear Jewellery"
    },
    {
        id : 5,
        img: portfolio5,
        name : "Wedding of Alexa & Robert",
        slug : "wedding-of-alexa-&-robert",
        sub : "Wedding, Wedding Destination"
    },
    {
        id : 6,
        img: portfolio6,
        name : "Wedding of Alexa & Robert",
        slug : "wedding-of-alexa-&-robert",
        sub : "Wedding, Wedding Destination"
    },
    {
        id : 7,
        img: portfolio7,
        name : "Wedding of Alexa & Robert",
        slug : "wedding-of-alexa-&-robert",
        sub : "Wedding, Wedding Destination"
    },

    {
        id : 8,
        img: portfolio8,
        name : "Wedding of Alexa & Robert",
        slug : "wedding-of-alexa-&-robert",
        sub : "Wedding, Wedding Destination"
    },

];
