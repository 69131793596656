export const HeaderData = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "about us",
    path: "/about-us",
    heading: "About Us",
  },
  {
    title: "services",
    path: "#",
    subNav: [
      {
        subTitle: "our services",
        path: "/our-services",
      },
      {
        subTitle: "services details",
        path: "/our-services/event-planner",
      },
    ],
  },
  {
    title: "Pages",
    path: "#",
    subNav: [
      {
        subTitle: "our portfolio",
        path: "/our-portfolio",
      },
      {
        subTitle: "portfolio details",
        path: "/our-portfolio/wedding-of-alexa-&-robert",
      },
      {
        subTitle: "our team",
        path: "/our-team",
      },

      {
        subTitle: "testimonial",
        heading: "Clients Reviews",
        path: "/testimonial",
      },
      {
        subTitle: "pricing plan",
        path: "/pricing-plan",
      },

      {
        subTitle: "error 404",
        path: "/error-404",
      },
      {
        subTitle: "coming soon",
        path: "/coming-soon",
      },
    ],
  },
  {
    title: " Our Blogs",
    path: "#",
    subNav: [
      {
        subTitle: "our blogs",
        path: "/our-blogs",
      },
      {
        subTitle: "blog details",
        path: "/our-blogs/how-to-keep-motivated-as-a-wedding-planner",
      },
    ],
  },
  {
    title: "contact us",
    path: "/contact-us",
  },
  {
    title: "book a consultation",
    path: "/contact-us",
  },
];
