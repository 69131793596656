import React from "react";
import Layout from "../Components/Layout";
import BannerGlobal from "../Components/BannerGlobal";
import Address from "../Components/Address";
import Contacts from "../Components/Contacts";
import useDocumentTitle from "../Hooks/PageTitle";

const ContactsPage = () => {
  useDocumentTitle("TRoo Wedding Planner | Contact Page")
  return (
    <Layout>
      <BannerGlobal title= "Let’s Fimf The Perfect One"/>
      <Address/>
      <Contacts/>
    </Layout>
  );
};

export default ContactsPage;
