import React from "react";
import highlightImg from "../images/highlightImg1.png";

const Highlight1 = () => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2">
      <div className="p-8 flex flex-col gap-5 bg-troo-primary text-troo-white">
        <h2>
          We Devote Time And Effort To Provide Quality Services To Our
          Customers.
        </h2>
        <div className="flex flex-col md:flex-row gap-5">
          <p>Want to Planning for Wedding? </p>
          <p className="font-bold underline">Contact With Us</p>
        </div>
      </div>
      <div className="bg-troo-primary">
        <img className="w-full h-full" src={highlightImg} alt="highjlightimg" />
      </div>
    </div>
  );
};

export default Highlight1;
