import React from "react";
import twoGlass from "../images/twoglass.svg";
import spiral from "../images/arrowSpiral.svg"
import { FiPlay } from "react-icons/fi";

const VideoSection = () => {
  return (
    <div className="bg-video-bg bg-no-repeat bg-cover bg-center relative w-full h-full before:absolute before:content-[''] before:bg-troo-black before:opacity-50 before:w-full before:top-0 before:left-0 before:h-full before:z-[0] py-12">
      <div className="main_container1 relative z-[9] py-12 lg:py-20">
        <div className="flex flex-col gap-12 justify-center items-center  text-troo-white ">
          <div>
            <img src={twoGlass} alt="twoGlass" />
          </div>
          <h2>We Will Make Your Wedding Amazing</h2>
          <p>Watch the Video</p>

          <div  onClick={() => window.my_modal_2.showModal()} className="relative flex items-center justify-center bg-troo-white text-troo-primaryDark rounded-full p-5 text-3xl z-[1] ">
            <div className="absolute w-full h-full bg-troo-white rounded-full bg-opacity-40 scale-125 -z-[1] animate-pulse animate-infinite duration-100 delay-75"></div>
            <div className="absolute w-full h-full bg-troo-white rounded-full bg-opacity-30 scale-150 -z-[1] animate-pulse animate-infinite duration-300 delay-200"></div>
            <div className="absolute w-full h-full bg-troo-white rounded-full bg-opacity-20 scale-[1.75] -z-[1] animate-pulse animate-infinite duration-700 delay-500"></div>
            <FiPlay className="text-troo-secondary" />
            <div className="absolute -right-24 -top-12">
                <img src={spiral} alt="spiralArrow" />
            </div>
          </div>
          <dialog id="my_modal_2" className="modal">
            <form
              method="dialog"
              className="modal-box w-11/12 max-w-5xl p-0 overflow-hidden"
            >
              <iframe
                width="100%"
                height="450"
                src="https://www.youtube.com/embed/DUfXdcpEfMs"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </form>
            <form method="dialog" className="modal-backdrop">
              <button>close</button>
            </form>
          </dialog>
        
        </div>
      </div>
    </div>
  );
};

export default VideoSection;
