import React, { useState } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";

const Counter = () => {
  const [counter, setCounter] = useState(false);
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 mt-12 bg-troo-tertiary">
      <div className="transition-all ease-in-out duration-500 group rounded-s">
        <div className="flex flex-col gap-4 items-center p-5 md:p-10 relative before:absolute before:w-[1px] before:h-[100px] before:bg-troo-primary before:right-0 before:bottom-0 before:top-0 before:opacity-50 before:m-auto before:transition-all before:ease-in-out before:duration-500 hover:before:h-full ">
          <h2 className="text-troo-secondary lg:text-6xl text-3xl  transition-all duration-500 ease-in-out">
            <ScrollTrigger
              onEnter={() => setCounter(true)}
              onExit={() => setCounter(false)}
            >
              {counter && (
                <CountUp start={1000} end={3264} duration={3} delay={0} />
              )}
              <strong>+</strong>
            </ScrollTrigger>
          </h2>
          <h3 className="text-troo-primary">Happy Clients</h3>
        </div>
      </div>
      <div className=" transition-all ease-in-out duration-500 group">
        <div className="flex flex-col gap-4 items-center p-5 md:p-10 relative before:absolute before:w-[1px] before:h-[100px] before:bg-troo-primary before:right-0 before:bottom-0 before:top-0 before:opacity-50 before:m-auto before:transition-all before:ease-in-out before:duration-500 hover:before:h-full ">
          <h2 className="text-troo-secondary lg:text-6xl text-3xl  transition-all duration-500 ease-in-out">
            <ScrollTrigger
              onEnter={() => setCounter(true)}
              onExit={() => setCounter(false)}
            >
              {counter && (
                <CountUp start={4000} end={5045} duration={3} delay={0} />
              )}
              <strong>+</strong>
            </ScrollTrigger>
          </h2>
          <h3 className="text-troo-primary">Year Experience</h3>
        </div>
      </div>
      <div className=" transition-all ease-in-out duration-500 group">
        <div className="flex flex-col gap-4 items-center p-5 md:p-10 relative before:absolute before:w-[1px] before:h-[100px] before:bg-troo-primary before:right-0 before:bottom-0 before:top-0 before:opacity-50 before:m-auto before:transition-all before:ease-in-out before:duration-500 hover:before:h-full ">
          <h2 className="text-troo-secondary lg:text-6xl text-3xl  transition-all duration-500 ease-in-out">
            <ScrollTrigger
              onEnter={() => setCounter(true)}
              onExit={() => setCounter(false)}
            >
              {counter && <CountUp start={1} end={50} duration={3} delay={0} />}
              <strong>+</strong>
            </ScrollTrigger>
          </h2>
          <h3 className="text-troo-primary">World Wide Branches</h3>
        </div>
      </div>
      <div className=" transition-all ease-in-out duration-500 group rounded-e">
        <div className="flex flex-col gap-4 items-center p-5 md:p-10 relative ">
          <h2 className="text-troo-secondary lg:text-6xl text-3xl  transition-all duration-500 ease-in-out">
            <ScrollTrigger
              onEnter={() => setCounter(true)}
              onExit={() => setCounter(false)}
            >
              {counter && (
                <CountUp start={1} end={26} duration={3} delay={0} />
              )}
              <strong>+</strong>
            </ScrollTrigger>
          </h2>
          <h3 className="text-troo-primary">In-house Staffs</h3>
        </div>
      </div>
    </div>
  );
};

export default Counter;
