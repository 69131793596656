import React from "react";
import subHeading from "../images/subHeadingImg.svg";

const Heading = ({ heading, title, textLight, isCenter }) => {
  return (
    <div
      className={`flex flex-col  gap-6 ${
        textLight ? "text-troo-white" : "text-troo-black"
      } ${isCenter ? "text-center items-center" : "text-left"}`}
    >
      <div className="flex gap-3 items-center">
        <img src={subHeading} alt="subHeading" />
        <h3>{title}</h3>
      </div>
      <h2 className="max-w-[750px]">{heading}</h2>
    </div>
  );
};

export default Heading;
