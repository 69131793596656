import React from 'react'
import Layout from '../../Components/Layout'
import BannerGlobal from '../../Components/BannerGlobal'
import ServiceDetails from '../../Components/ServiceDetails'
import Faqs from '../../Components/Faqs'
import Feedbacks from '../../Components/Feedbacks'
import useDocumentTitle from '../../Hooks/PageTitle'

const ServiceDetailsPage = () => {
  useDocumentTitle("TRoo Wedding Planner | Service Details Page")
  return (
    <Layout>
        <BannerGlobal title= "Our Recently Works"/>
        <ServiceDetails/>
        <Faqs/>
        <Feedbacks isDetailsPage/>
    </Layout>
  )
}

export default ServiceDetailsPage