import React from 'react'
import Layout from '../../Components/Layout'
import BannerGlobal from '../../Components/BannerGlobal'
import Services from '../../Components/Services'
import useDocumentTitle from '../../Hooks/PageTitle'

const ServicePage = () => {
  useDocumentTitle("TRoo Wedding Planner | Service Page")
  return (
    <Layout>
        <BannerGlobal title="What We Do Four You"/>
        <Services isServicePage/>
    </Layout>
  )
}

export default ServicePage