import React from 'react'
import Layout from '../Components/Layout'
import BannerGlobal from '../Components/BannerGlobal'
import Abouts from '../Components/Abouts'
import VideoSection from '../Components/VideoSection'
import Feedbacks from '../Components/Feedbacks'
import Teams from '../Components/Teams'
import useDocumentTitle from '../Hooks/PageTitle'

const AboutPage = () => {
  useDocumentTitle("TRoo Wedding Planner | Abouts Page")
  return (
    <Layout>
        <BannerGlobal title="About Us"/>
        <Abouts/>
        <VideoSection/>
        <Feedbacks/>
        <Teams isabout/>
    </Layout>
  )
}

export default AboutPage