import React from 'react'
import Layout from '../../Components/Layout'
import BannerGlobal from '../../Components/BannerGlobal'
import PortfolioDetails from '../../Components/PortfolioDetails'
import useDocumentTitle from '../../Hooks/PageTitle'

const ProjectDetailsPage = () => {
  useDocumentTitle("TRoo Wedding Planner | Project Details")
  return (
   <Layout>
    <BannerGlobal title="Our Recently Works"/>
    <PortfolioDetails/>
   </Layout>
  )
}

export default ProjectDetailsPage