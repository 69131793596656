import React from 'react'
import Layout from '../../Components/Layout'
import BannerGlobal from '../../Components/BannerGlobal'
import PricingCard from '../../Components/PricingCard'
import useDocumentTitle from '../../Hooks/PageTitle'

const PricingCardPage = () => {
  useDocumentTitle("TRoo Wedding Planner | Pricing Card Page")
  return (
    <Layout>
        <BannerGlobal title= "We Have Plan Small to Large"/>
        <PricingCard/>
    </Layout>
  )
}

export default PricingCardPage