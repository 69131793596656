import React from 'react'
import Layout from '../../Components/Layout'
import BannerGlobal from '../../Components/BannerGlobal'
import Teams from '../../Components/Teams'
import useDocumentTitle from '../../Hooks/PageTitle'

const TeamsPage = () => {
  useDocumentTitle("TRoo Wedding Planner | Teams Page")
  return (
    <Layout>
        <BannerGlobal title="We Have Plan Small to Large"/>
        <Teams isTeamPage/>
    </Layout>
  )
}

export default TeamsPage