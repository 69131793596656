import React from 'react'
import ComingSoon from '../../Components/ComingSoon'
import useDocumentTitle from '../../Hooks/PageTitle'

const ComingSoonPage = () => {
  useDocumentTitle("TRoo Wedding Planner | ComingSoon Page")
  return (
   <ComingSoon/>
  )
}

export default ComingSoonPage