import React, { useEffect, useRef } from "react";
import logo from "../images/Logo.png";
import humburger from "../images/Humberger.svg";
import { RiMenu3Fill } from "react-icons/ri";
import { FiMail, FiUser } from "react-icons/fi";
import { PiChatsCircle } from "react-icons/pi";
import { HeaderData } from "../Data/HeaderData";
import { Link } from "react-router-dom";
import NavMenu from "./NavMenu";
import AccordianMenu from "./AccordianMenu";
import { useLocation } from "react-router-dom";

const Header = () => {
  const location = useLocation();
  let arr = location.pathname?.split("/");
  let lastvalue = arr[arr.length - 1].trim().replace(/-/g, " ");
  const navbar_ref = useRef();
  function handleScroll() {
    if (window.pageYOffset > 10 || document.documentElement.scrollTop > 10) {
      if (navbar_ref.current) {
        navbar_ref.current.style.top = "0px";
      }
    } else {
      if (navbar_ref.current) {
        navbar_ref.current.style.top = "-150px";
      }
    }
  }
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="show_nav" ref={navbar_ref}>
        <input id="my-drawer-2" type="checkbox" className="drawer-toggle"/>
        <div className="main_container2 flex  justify-between items-center flex-wrap py-2 ">
          <div className="mr-7 xl:mr-24 flex items-center gap-10">
            <div className="drawer-content hidden sm:block">
              <label
                htmlFor="my-drawer-1"
                className="drawer-button cursor-pointer"
              >
                <img src={humburger} alt="humburger" />
              </label>
            </div>
            <Link className="w-[250px] xl:w-[300px] 2xl:w-full" to="/">
              <img src={logo} alt="logo" />
            </Link>
          </div>
          <nav className=" py-4 lg:py-0">
            <div className="flex justify-between items-center">
              <div className="lg:flex hidden items-center ">
                <ul className="flex h-full">
                  {HeaderData.slice(0, 6).map((item, i) => {
                    return <NavMenu key={i} item={item} />;
                  })}
                </ul>
              </div>
              <div className="md:block lg:hidden text-troo-secondary drawer-content">
                <label htmlFor="my-drawer-2">
                  <RiMenu3Fill className="text-[30px] cursor-pointer" />
                </label>
              </div>
            </div>
          </nav>
          <div className="hidden 4xl:block">
            <div className="flex items-center gap-5 ">
              <div>
                <div className="w-10 h-10 flex items-center justify-center bg-troo-primary bg-opacity-20 text-troo-primary">
                  <PiChatsCircle />
                </div>
              </div>
              <div>
                <p>+44 123 456 7890</p>
                <p>contact@troothemes.com</p>
              </div>
            </div>
          </div>
          <div className="hidden xl:block">
            {HeaderData.slice(-1).map((e, i) => {
              return (
                <Link
                  key={i}
                  to={e.path}
                  className="btn1 capitalize bg-troo-primary text-troo-white hover:bg-troo-secondary"
                >
                  {e.title}
                </Link>
              );
            })}
          </div>
        </div>
        <div className="drawer-side z-100">
          <label htmlFor="my-drawer-2" className="drawer-overlay"></label>
          <div className="menu flex flex-col p-4 w-[80%] md:w-80 h-full overflow-scroll  bg-troo-white text-troo-black">
            <div className="flex w-full flex-col items-start gap-4 lg:gap-6 2xl:gap-8">
              <div className="flex items-center">
                <Link to="/">
                  <img
                    src={logo}
                    alt="logo"
                    className="w-[200px] md:w-[200px] xl:w-[250px]"
                  />
                </Link>
              </div>
              <div className="flex w-full flex-col items-start gap-2 lg:gap-6 2xl:gap-8">
                {HeaderData.slice(0, 6).map((e, i) => {
                  return !e?.subNav ? (
                    <Link
                      key={i}
                      to={e.path}
                      className={`py-1 flex items-center capitalize text-lg ${
                        e.title === lastvalue ? "text-troo-primary" : ""
                      }`}
                    >
                      {e.title}
                    </Link>
                  ) : (
                    <AccordianMenu title={e.title} subMenu={e.subNav} />
                  );
                })}
              </div>
              <div className="">
                {HeaderData.slice(-1).map((e, i) => {
                  return (
                    <Link key={i} to={e.path} className="btn1 bg-troo-secondary capitalize">
                      {e.title}
                    </Link>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="drawer drawer-end">
        <input id="my-drawer-1" type="checkbox" className="drawer-toggle" />
        <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
        <header className="relative w-full left-[50%] translate-x-[-50%] z-10">
          <div className="main_container2 flex justify-between items-center flex-wrap py-2 ">
            <div className="mr-7 xl:mr-24 flex items-center gap-10">
              <div className="drawer-content hidden sm:block">
                <label
                  htmlFor="my-drawer-1"
                  className="drawer-button cursor-pointer"
                >
                  <img src={humburger} alt="humburger" />
                </label>
              </div>
              <Link className="w-[250px] xl:w-[300px] 2xl:w-full" to="/">
                <img src={logo} alt="logo" />
              </Link>
            </div>
            <nav className=" py-4 lg:py-0">
              <div className="flex justify-between items-center">
                <div className="lg:flex hidden items-center ">
                  <ul className="flex h-full">
                    {HeaderData.slice(0, 6).map((item, i) => {
                      return <NavMenu key={i} item={item} />;
                    })}
                  </ul>
                </div>
                <div className="md:block lg:hidden text-troo-secondary drawer-content">
                  <label htmlFor="my-drawer-2">
                    <RiMenu3Fill className="text-[30px] cursor-pointer" />
                  </label>
                </div>
              </div>
            </nav>
            <div className="hidden 4xl:block">
              <div className="flex items-center gap-5">
                <div>
                  <div className="w-10 h-10 flex items-center justify-center bg-troo-primary bg-opacity-20 text-troo-primary">
                    <PiChatsCircle />
                  </div>
                </div>
                <div>
                  <p>+44 123 456 7890</p>
                  <p>contact@troothemes.com</p>
                </div>
              </div>
            </div>
            <div className="hidden xl:block">
              {HeaderData.slice(-1).map((e, i) => {
                return (
                  <Link
                    key={i}
                    to={e.path}
                    className="btn1 capitalize bg-troo-primary text-troo-white hover:bg-troo-secondary"
                  >
                    {e.title}
                  </Link>
                );
              })}
            </div>
          </div>
        </header>
        <div className="drawer-side z-[9999]">
          <label htmlFor="my-drawer-1" className="drawer-overlay"></label>
          <div className="menu w-96  min-h-full bg-troo-white text-base-content">
            <div className="p-5 bg-troo-white">
              <div className="flex justify-center items-center">
                <img src={logo} alt="logo" />
              </div>
              <div className="flex flex-col justify-center py-10 gap-6">
                <h3 className="text-center">About Us</h3>
                <p className="text-center">
                  Lörem ipsum od ohet dilogi. Bell trabel, samuligt, ohöbel utom
                  diska. FAR paratyp i muvåning, och pesask vyfisat. Viktiga
                  poddradio har un mad och inde.
                </p>
                <div className="text-center">
                  <Link className="btn1 bg-troo-primary text-troo-secondary hover:bg-troo-secondary hover:text-troo-primary transition-all ease-in-out duration-500 ">
                    Learn More
                  </Link>
                </div>
              </div>
              <div>
                <h3 className="text-center">Get a Free Quote</h3>
                <div className="flex flex-col items-center gap-5 pt-6">
                  <div className="relative w-full ">
                    <p className="mb-3">Full Name</p>
                    <FiUser className="absolute top-[70%] left-3 transform -translate-y-1/2" />
                    <input
                      type="text"
                      placeholder="Full Name"
                      className="p-4 pl-10 bg-transparent border border-troo-hrtag rounded w-full placeholder:text-troo-secondary"
                    />
                  </div>
                  <div className="relative w-full">
                    <p className="mb-3">Your Email</p>
                    <FiMail className="absolute top-[70%] left-3 transform -translate-y-1/2" />
                    <input
                      type="email"
                      placeholder="Your Email Address"
                      className="p-4 pl-10 bg-transparent border border-troo-hrtag rounded w-full placeholder:text-troo-secondary"
                    />
                  </div>
                  <div className="w-full">
                    <p className="mb-3">Write Comment</p>
                    <textarea
                      placeholder="White something to me"
                      className="p-5 bg-transparent border focus:outline-none focus-visible:ring-1 rounded w-full"
                      rows={3}
                    />
                  </div>
                  <Link className="btn1 w-full text-center bg-troo-primary text-troo-secondary hover:text-troo-primary hover:bg-troo-secondary transition-all ease-in-out duration-500">
                    Send Message
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
