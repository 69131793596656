import React from "react";
import Heading from "./Heading";
import { FeedbackData } from "../Data/FeedbackData";
import quote from "../images/quote.svg";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const Feedbacks = ({ isTestimonialPage,isDetailsPage }) => {
  return (
    <div
      className={`bg-feedback-bg bg-no-repeat w-full  ${
        isTestimonialPage || isDetailsPage ? "pt-12 lg:pt-24 pb-12 lg:pb-72" : "py-12 lg:py-24"
      }`}
    >
      <div className="main_container1">
        {!isTestimonialPage && (
          <div className="flex flex-col lg:flex-row gap-10 justify-center mb-12">
            <div className="w-full">
              <Heading
                heading={"What Our Valuable Client Say About Our Services"}
                title={"What Our Clients Say"}
              />
            </div>
            <div className="flex items-end">
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled.
              </p>
            </div>
          </div>
        )}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 ">
          {FeedbackData.map((e, i) => (
            <motion.div  initial={{ x: -50, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ type: "spring", stiffness: "100", delay:0.5 * i }}
              key={i}
              className="bg-troo-tertiary rounded flex flex-col sm:flex-row justify-between gap-8 lg:gap-12 p-5 lg:p-12 hover:bg-troo-primary hover:text-troo-white transition-all duration-500 ease-in-out"
            >
              <div className="">
                <div className="relative">
                  <div className="relative before:content-[''] before:absolute before:w-full before:h-full before:bg-troo-secondary before:rounded-full before:top-1 before:z-[-1] z-[1] inline-flex">
                    <img className="rounded-full" src={e.img} alt={e.name} />
                    <div className="absolute -translate-y-1/2 top-1/2 -right-5 rounded-full bg-troo-secondary p-2 flex justify-center items-center z-[2]">
                      <img className="w-4 h-4" src={quote} alt="quote" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-6 w-full sm:w-[70%]">
                <p>{e.para}</p>
                <div className="flex flex-col 2xl:flex-row justify-between gap-6">
                  <div className="flex gap-3 items-center">
                    <h4>{e.name}</h4>
                    <p>{e.desg}</p>
                  </div>
                  <div className="flex gap-1 text-troo-secondary">
                    {e.rating}
                  </div>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
        {!isTestimonialPage && (
          <div className="text-center mt-12">
            <Link to="/testimonial" className="btn1 bg-troo-primary hover:bg-troo-secondary text-troo-white">
              View All Reviews
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default Feedbacks;
