import {BiPhone } from "react-icons/bi";
import { HiOutlineMailOpen } from "react-icons/hi";
import { CiLocationOn } from "react-icons/ci";

export const AddressData = [
    {
        id : 1,
        img : <BiPhone/>,
        para : "Need Help? Call Us on",
        sub1 :"+44 123 456 7890", 
    },
    {
        id : 2,
        img : <HiOutlineMailOpen/>,
        para : "Email Address",
        sub1 :"contact@troothemes.com",
    },
    {
        id : 3,
        img : <CiLocationOn/>,
        para : "Office Address",
        sub1 :"4516 School Street Danbury, CT, Canada",
    },
]