import React from "react";
import AboutImg from "../images/aboutImg.png";
import Heading from "./Heading";
import { BsArrowRightCircle } from "react-icons/bs";
import { Link } from "react-router-dom";
import Counter from "./Counter";
import { motion } from "framer-motion";

const Abouts = () => {
  return (
    <div className="py-12 lg:py-24">
      <div className="main_container1">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          <motion.div
            initial={{ x: -100, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ duration: 2, type: "spring" }}
            className="w-full h-full relative before:content-[''] before:bg-about-Bg before:w-full before:bg-no-repeat before:bg-center before:bg-cover  before:h-full before:absolute"
          >
            <img className="w-full" src={AboutImg} alt="aboutimg" />
          </motion.div>
          <div className="flex flex-col gap-5">
            <motion.div
              initial={{ x: 100, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ duration: 2, type: "spring" }}
            >
              <Heading
                heading={
                  "Professional, Personal, and Affordable Wedding Planner"
                }
                title={"About TRoo Wedding Planner"}
              />
            </motion.div>
            <h4>
              We’re committed to delivering the best wedding planning for your
              awesome wedding celebration
            </h4>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic.
            </p>
            <div className="flex flex-col gap-5 py-5">
              <motion.p
                initial={{ x: -50, opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                transition={{
                  type: "spring",
                  stiffness: "100",
                  delay: 0.5 * 1,
                }}
                className="flex items-center gap-3"
              >
                <BsArrowRightCircle /> Delivering the most memorable weddings,
                in the least amount of time
              </motion.p>
              <motion.p
               initial={{ x: -50, opacity: 0 }}
               whileInView={{ x: 0, opacity: 1 }}
               transition={{
                 type: "spring",
                 stiffness: "100",
                 delay: 0.5 * 2,
               }}
              className="flex items-center gap-3">
                <BsArrowRightCircle /> Because happy couples deserve beautiful
                wedding
              </motion.p>
              <motion.p 
              initial={{ x: -50, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{
                type: "spring",
                stiffness: "100",
                delay: 0.5 * 3,
              }}
              className="flex items-center gap-3">
                <BsArrowRightCircle /> A wedding that is True as you are!
              </motion.p>
              <motion.p
              initial={{ x: -50, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{
                type: "spring",
                stiffness: "100",
                delay: 0.5 * 4,
              }}
              className="flex items-center gap-3">
                <BsArrowRightCircle />
                Professional, personal, and affordable wedding planner
              </motion.p>
            </div>
            <div>
              <Link
                to="/about-us"
                className="btn1 bg-troo-primary hover:bg-troo-secondary text-troo-white"
              >
                Learn More About Us
              </Link>
            </div>
          </div>
        </div>
        <div>
          <Counter />
        </div>
      </div>
    </div>
  );
};

export default Abouts;
