import React from 'react'
import Layout from '../../Components/Layout'
import BannerGlobal from '../../Components/BannerGlobal'
import Portfolio from '../../Components/Portfolio'
import useDocumentTitle from '../../Hooks/PageTitle'

const ProjectPage = () => {
  useDocumentTitle("TRoo Wedding Planner | Projects Page")
  return (
    <Layout>
        <BannerGlobal title="Our Recently Works"/>
        <Portfolio isPortfolioPage/>

    </Layout>
  )
}

export default ProjectPage