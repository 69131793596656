import React from "react";
import logo2 from "../images/Logo2.png";
import { Link } from "react-router-dom";
import { HiArrowSmallRight } from "react-icons/hi2";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
} from "react-icons/fa";
import Brands from "./Brands";

const Footer = () => {
  const siteLink = "https://www.troothemes.com/"
  return (
    <div className="bg-footer-Bg bg-no-repeat w-full h-full bg-troo-black pt-12 lg:pt-24">
      <div className="main_container1">
        <div className="lg:-mt-72 mb-24 relative">
          <Brands />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <div className="bg-troo-primary bg-opacity-5 text-troo-white flex flex-col gap-5 lg:p-10 p-5 items-center justify-center">
            <h3>Call Us</h3>
            <h4>+44 123 456 7890</h4>
            <h4>+44 123 456 7890</h4>
            <h3>Email Us</h3>
            <h4>contact@troothemes.com</h4>
            <Link className="font-bold" to={siteLink}>info@troothemes.com</Link>
          </div>
          <div className="bg-troo-primary bg-opacity-5 text-troo-white flex flex-col gap-5 lg:p-10 p-5 items-center justify-center">
            <Link to="/">
              <img src={logo2} alt="logo2" />
            </Link>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text.
            </p>
            <Link className="flex items-center gap-3">
              Read More <HiArrowSmallRight />
            </Link>
          </div>
          <div className="bg-troo-primary bg-opacity-5 text-troo-white flex flex-col gap-5 lg:p-10 p-5 items-center justify-center">
            <h3 className="font-Infont">Our Address</h3>
            <h3 className="font-Infont">4516 School Street Danbury,</h3>
            <h3 className="font-Infont">CT, Canada</h3>
            <h3 className="font-Infont">Follow Us on</h3>
            <div className="flex gap-3">
              <div className="w-8 h-8 flex justify-center items-center bg-troo-white hover:bg-troo-primary transition-all ease-in-out duration-500 rounded-full text-troo-black hover:text-troo-white">
                <FaFacebookF />
              </div>
              <div className="w-8 h-8 flex justify-center items-center bg-troo-white hover:bg-troo-primary transition-all ease-in-out duration-500 rounded-full text-troo-black hover:text-troo-white">
                <FaInstagram />
              </div>
              <div className="w-8 h-8 flex justify-center items-center bg-troo-white hover:bg-troo-primary transition-all ease-in-out duration-500 rounded-full text-troo-black hover:text-troo-white">
                <FaLinkedinIn />
              </div>
              <div className="w-8 h-8 flex justify-center items-center bg-troo-white hover:bg-troo-primary transition-all ease-in-out duration-500 rounded-full text-troo-black hover:text-troo-white">
                <FaTwitter />
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-8 items-center text-troo-white pb-12 border-b border-troo-white border-opacity-10 py-12">
          <h2>Subscribe Our Newslatter</h2>
          <p className="mx-auto max-w-[800px] text-center">
            Troothemes tech transformers are on a mission to arm creators with
            marketing materials that enhance digital presence.
          </p>
          <form className="w-full md:w-[700px] relative">
            <input
              type="text"
              placeholder="Enter your email"
              className="w-full px-10 py-5 bg-transparent border border-troo-white border-opacity-10 rounded"
            />
            <button className="absolute right-0 bg-troo-secondary hover:bg-troo-primary h-full btn1">
              SUBSCRIBE
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Footer;
