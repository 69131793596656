import React, { useEffect, useState } from "react";
import { PortfolioData } from "../Data/PortfolioData";
import { Link } from "react-router-dom";

const RelatedPortfolio = ({ currentSlug }) => {
  const [relatedProject, setRelatedProject] = useState([]);
  useEffect(() => {
    const filterProjectList = PortfolioData.filter(
      (data) => data.slug !== currentSlug
    );
    setRelatedProject(filterProjectList);
    window.scrollTo(0, 0);
  }, [currentSlug]);

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
      {relatedProject.slice(0, 2).map((e, i) => (
        <div>
          <Link to={`/our-portfolio/${e.slug}`} className="flex flex-col gap-5" key={i}>
          <div>
            <img className="w-full max-h-[400px]" src={e.img} alt={e.name} />
          </div>
          <p>{e.sub}</p>
          <h4>{e.name}</h4>
        </Link>
        </div>
      ))}
    </div>
  );
};

export default RelatedPortfolio;
