import React, { useEffect, useState } from "react";
import { BlogsData } from "../Data/BlogsData";
import { BsArrowRightCircle } from "react-icons/bs";
import { useParams } from "react-router-dom";
import { ServiceData } from "../Data/ServiceData";
import RelatedService from "./RelatedService";

const ServiceDetails = () => {
  const { slug } = useParams();
  const [serviceDetails, setServiceDetails] = useState();
  useEffect(() => {
    const filteredData = ServiceData.filter((data) => data.slug === slug);
    setServiceDetails(filteredData[0]);
  }, [slug]);
  return (
    <div className="py-12 lg:py-24">
      <div className="main_container1">
        <div className="flex flex-col gap-6">
          <h2>{serviceDetails?.name || "Event Planner"}</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet,
            consectetur notted adipisicing elit sed do eiusmod tempor incididunt
            ut labore et simply free Lorem ipsum dolor sit amet, consectetur
            notted adipisicing elit sed do eiusmod tempor incididunt ut labore
            et simply free text dolore magna aliqua lonm andhn.text dolore magna
            aliqua lonm andhn.notted adipisicing elit sed do eiusmod tempor
            incididunt.
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet,
            consectetur notted adipisicing elit sed do eiusmod tempor incididunt
            ut labore et simply free Lorem ipsum dolor sit amet, consectetur
            notted adipisicing elit sed do eiusmod tempor incididunt ut labore
            et simply free.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {BlogsData.slice(0, 3).map((e) => (
              <div className="" key={e}>
                <img src={e.img} alt={e.name} />
              </div>
            ))}
          </div>
          <h2>Service Benifits</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet,
            consectetur notted adipisicing elit sed do eiusmod tempor incididunt
            ut labore et simply free Lorem ipsum dolor sit amet, consectetur
            notted adipisicing elit sed do eiusmod tempor incididunt ut labore
            et simply free text dolore magna aliqua lonm andhn.text dolore magna
            aliqua lonm andhn.notted adipisicing elit sed do eiusmod tempor
            incididunt.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
            <div className="flex flex-col gap-4">
              <p className="flex items-center gap-3">
                <BsArrowRightCircle />
                Personal, and affordable wedding planner
              </p>
              <p className="flex items-center gap-3">
                <BsArrowRightCircle />
                Because couples deserve beautiful wedding
              </p>
              <p className="flex items-center gap-3">
                <BsArrowRightCircle />A wedding that is True as you are!
              </p>
              <p className="flex items-center gap-3">
                <BsArrowRightCircle />
                Professional, affordable wedding planner
              </p>
            </div>
            <div className="flex flex-col gap-4">
              <p className="flex items-center gap-3">
                <BsArrowRightCircle />
                Personal, and affordable wedding planner
              </p>
              <p className="flex items-center gap-3">
                <BsArrowRightCircle />
                Because couples deserve beautiful wedding
              </p>
              <p className="flex items-center gap-3">
                <BsArrowRightCircle />A wedding that is True as you are!
              </p>
              <p className="flex items-center gap-3">
                <BsArrowRightCircle />
                Professional, affordable wedding planner
              </p>
            </div>
            <div className="flex flex-col gap-4">
              <p className="flex items-center gap-3">
                <BsArrowRightCircle />
                Personal, and affordable wedding planner
              </p>
              <p className="flex items-center gap-3">
                <BsArrowRightCircle />
                Because couples deserve beautiful wedding
              </p>
              <p className="flex items-center gap-3">
                <BsArrowRightCircle />A wedding that is True as you are!
              </p>
              <p className="flex items-center gap-3">
                <BsArrowRightCircle />
                Professional, affordable wedding planner
              </p>
            </div>
          </div>
          <h2>Related Services</h2>
          <RelatedService currentSlug = {slug}/>
        </div>
      </div>
    </div>
  );
};

export default ServiceDetails;
