import React from "react";
import { AddressData } from "../Data/AddressData";
import { motion } from "framer-motion";

const Address = () => {
  return (
    <div className="py-12 lg:py-16">
      <div className="main_container1 flex flex-col items-center">
        <div className="flex flex-col md:flex-row justify-center w-[80%] gap-6">
          {AddressData.map((e, i) => (
            <motion.div
            initial={{ x: 50, y : 50, opacity: 0, scale: 0.5 }}
            whileInView={{ x: 0, y: 0,  opacity: 1, scale: 1 }}
            transition={{ type: "spring", stiffness: "100", delay: 0.2 * i }}
            key={i}
            className="p-5 flex flex-col w-full justify-center items-center gap-3 border border-troo-primary border-opacity-50 group ">
              <div className="h-20 w-20 text-3xl rounded-full flex justify-center items-center group-hover:bg-troo-primary bg-troo-secondary transition-all ease-in-out duration-500 text-troo-white border font-medium">
                {e.img}
              </div>
              <p>{e.para}</p>
              <h4 className="text-troo-secondary text-center">{e.sub1}</h4>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Address;
