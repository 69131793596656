import blog1 from "../images/blog1.png";
import blog2 from "../images/blog2.png";
import blog3 from "../images/blog3.png";
import blog4 from "../images/blog4.png";
import blog5 from "../images/blog5.png";
import blog6 from "../images/blog6.png";
import blog7 from "../images/blog7.png";
import blog8 from "../images/blog8.png";
import blog9 from "../images/blog9.png";

export const BlogsData = [
  {
    id: 1,
    img: blog1,
    name: "How to Keep Motivated as a Wedding Planner",
    slug : "how-to-keep-motivated-as-a-wedding-planner",
    date: "Date: 20 June, 2023",
  },
  {
    id: 2,
    img: blog2,
    name: "5 Tips On How To Be The Best Groomsman Ever",
    slug : "5-tips-on-how-to-be-the-best-groomsman-ever",
    date: "Date: 10 may, 2023",
  },
  {
    id: 3,
    img: blog3,
    name: "Is it possible to plan a wedding in 3 months?",
    slug : "ts-it-possible-to-plan-a-wedding-in-3-months?",
    date: "Date: 20 June, 2023",
  },
  {
    id: 4,
    img: blog4,
    name: "5 Tips On How To Be The Best Groomsman Ever",
    slug : "5-tips-on-how-to-be-the-best-groomsman-ever",
    date: "Date: 15 April, 2023",
  },
  {
    id: 5,
    img: blog5,
    name: "Is it possible to plan a wedding in 3 months?",
    slug : "ts-it-possible-to-plan-a-wedding-in-3-months?",
    date: "Date: 20 June, 2023",
  },
  {
    id: 6,
    img: blog6,
    name: "How to Keep Motivated as a Wedding Planner",
    slug : "how-to-keep-motivated-as-a-wedding-planner",
    date: "Date: 20 June, 2023",
  },
  {
    id: 7,
    img: blog7,
    name: "5 Tips On How To Be The Best Groomsman Ever",
    slug : "5-tips-on-how-to-be-the-best-groomsman-ever",
    date: "Date: 20 June, 2023",
  },
  {
    id: 8,
    img: blog8,
    name: "How to Keep Motivated as a Wedding Planner",
    slug : "how-to-keep-motivated-as-a-wedding-planner",
    date: "Date: 20 June, 2023",
  },
  {
    id: 9,
    img: blog9,
    name: "How to Keep Motivated as a Wedding Planner",
    slug : "ts-it-possible-to-plan-a-wedding-in-3-months?",
    date: "Date: 20 June, 2023",
  },
];
