import React from "react";
import comingImg from "../images/faqBg.png";
import logo2 from "../images/Logo.png";
import { Link } from "react-router-dom";
import { FiMail } from "react-icons/fi";
import subHeading from "../images/subHeadingImg.svg";

const ComingSoon = () => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-7">
      <div className="2xl:px-44 xl:px-28 lg:12 px-5 py-12 lg:py-24">
        <div>
          <img src={logo2} alt="logo2" />
        </div>
        <div className="flex flex-col gap-5 w-full py-16 mt-10">
          <div className="flex items-center gap-5 mb-8">
            <img src={subHeading} alt="subhedaing" />
            <h3>Be a guest at your own event</h3>
          </div>
          <h2>We Will Launching Our Website Very Soon</h2>
          <p>
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered aafrid lteration in some form, by
            injected humour, or randomised words.
          </p>
          <div>
            <p className="mb-2">Get Notifimy Me When It Lounch</p>
            <div className="flex flex-wrap relative w-full items-stretch mb-4">
              <FiMail className="absolute top-[52%] left-3 text-troo-black6 transform -translate-y-1/2" />
              <input
                className="w-[90%] py-[19px] border rounded px-5 block pl-10"
                type="email"
                placeholder="Enter your Email"
              />
              <Link className="py-4 px-5 rounded inline-flex justify-center items-center font-bold textarea-sm text-troo-white bg-troo-secondary absolute right-0">
                Submit Now!
              </Link>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <h4>Contact Us</h4>
          <h3 className="font-Infont">+44 987 654 3210</h3>
          <h3 className="font-Infont">contact@troothems.com</h3>
        </div>
      </div>
      <div>
        <img className="w-full h-full" src={comingImg} alt="comingimg" />
      </div>
    </div>
  );
};

export default ComingSoon;
