import React, { useEffect, useState } from "react";
import { ServiceData } from "../Data/ServiceData";
import { Link } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import Heading from "./Heading";
import { motion } from "framer-motion";

const Services = ({ isServicePage }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    if (isServicePage) {
      setData(ServiceData);
    } else {
      setData(ServiceData.slice(0, 4));
    }
  }, []);
  return (
    <div
      className={` w-full h-full  bg-troo-tertiary ${
        isServicePage
          ? " pt-12 lg:pt-24 pb-12 lg:pb-72"
          : "bg-service-bg bg-no-repeat py-12 lg:py-24"
      }`}
    >
      <div className="main_container1">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 ">
          {!isServicePage && (
            <div className="flex flex-col gap-5 items-center justify-center">
              <div>
                <Heading
                  heading={"Providing Solutions of Every Kind of Wedding"}
                  title={"What We Provide"}
                />
              </div>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text.
              </p>
            </div>
          )}
          {data.map((e, i) => (
            <motion.div
              initial={{ x: -50, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ type: "spring", stiffness: "100", delay: 0.5 * i }}
            >
              <Link
                to={`/our-services/${e.slug}`}
                key={i}
                className="bg-troo-white flex flex-col gap-4 p-7 group"
              >
                <div>
                  <div className="bg-troo-secondary rounded-full p-3 w-16 h-16 flex justify-center items-center group-hover:bg-troo-primary transition-all ease-in-out duration-500">
                    <img className="w-full" src={e.img} alt={e.name} />
                  </div>
                </div>
                <h3 className="font-Infont group-hover:text-troo-secondary transition-all duration-500 ease-in-out ">
                  {e.name}
                </h3>
                <p>{e.para}</p>
                <Link>
                  <p className="flex gap-3 items-center">
                    Read More <BsArrowRight />
                  </p>
                </Link>
              </Link>
            </motion.div>
          ))}
          {!isServicePage && (
            <div className="bg-troo-secondary w-full h-full relative before:bg-serviceCard-bg before:bg-no-repeat before:w-full before:h-full before:absolute flex items-center before:z-[-1] z-[1]">
              <div className="flex flex-col gap-4 p-7 justify-center ">
                <h3 className="font-Infont text-troo-white ">
                  +4 More Services
                </h3>
                <p className="text-troo-white">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
                <div>
                  <Link
                    to="/our-services"
                    className="btn1 bg-troo-white hover:bg-troo-primary"
                  >
                    View All Services
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Services;
