import React, { useEffect, useState } from "react";
import Heading from "./Heading";
import { TeamData } from "../Data/TeamData";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const Teams = ({isabout,isTeamPage}) => {
  const [data , setData] = useState([])
  useEffect(()=>{
    if(isTeamPage){
      setData(TeamData)
    }
    else{
      setData(TeamData.slice(0,4))
    }
  },[])
  return (
    <div className={`bg-troo-tertiary  ${isabout ? "pt-12 lg:pt-24 pb-12 lg:pb-72" : "py-12 lg:py-24"}`}>
      <div className="main_container1">
      {
        !isTeamPage && (  <div className="mb-12">
        <Heading
          heading={
            <>
              We Picked Best Planner That
              <br />
              Are Suited Just for You.
            </>
          }
          title={"Meet Our Planner"}
          isCenter
        />
      </div>)
      }
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {data.map((e, i) => (
            <motion.div  initial={{ y: -50, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ type: "spring", stiffness: "100", delay:0.5 * i }}>
              <Link className="bg-troo-white flex items-center flex-col gap-8 p-8 group " key={i}>
                <div className="relative flex justify-center items-center ">
                  <div className="relative before:absolute before:content-[''] before:bg-troo-secondary before:rounded-full before:bg-opacity-80 before:scale-0 group-hover:before:scale-100 before:transition-all before:duration-500 before:ease-in-out  before:w-full before:h-full group">
                    <img
                      className="rounded-full w-full"
                      src={e.img}
                      alt={e.name}
                    />
                  </div>
                  <div className="flex gap-2 absolute ">
                    {e.social.map((ele) => (
                      <div key={i}  className="w-8 h-8 flex justify-center items-center p-2 bg-troo-white hover:bg-troo-primary hover:text-troo-white transition-all duration-500 ease-in-out rounded-full scale-0 group-hover:scale-100">
                        {ele.icon}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="flex flex-col text-center gap-3">
                  <p>{e.sub}</p>
                  <h4 className="text-troo-secondary">{e.name}</h4>
                </div>
              </Link>
            </motion.div>
          ))}
        </div>
        <div className="text-center mt-12">
          <Link to="/our-team" className="btn1 bg-troo-primary hover:bg-troo-secondary text-troo-tertiary">
            View All Planner
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Teams;
