import React from "react";
import { Link } from "react-router-dom";
import subHeading from "../images/subHeadingImg.svg";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
} from "react-icons/fa";
import { motion } from "framer-motion";

const Banner = () => {
  return (
    <div className="flex">
      <div className="bg-banner-bg bg-no-repeat bg-center  w-full h-full relative z-[1] before:content-[''] before:absolute before:w-full before:h-full before:bg-troo-black before:opacity-70 before:z-[-1] lg:ml-24">
        <div className="flex flex-col gap-12 justify-center items-center py-12 lg:py-24 xl:py-40 2xl:py-60 text-troo-white">
          <motion.div 
          initial={{ x: -100, opacity : 0 }}
          whileInView={{ x: 0, opacity : 1 }}
          transition={{ duration: 2, type: "spring" }}
          className="flex items-center gap-8">
            <img src={subHeading} alt="subhedaing" />
            <h3>Be a guest at your own event</h3>
          </motion.div>
          <motion.h1
          initial={{ x: 100, opacity : 0 }}
          whileInView={{ x: 0, opacity : 1 }}
          transition={{ duration: 2, type: "spring" }}
          className="text-center">
            Planning a wedding is stressful enough <br /> let us make it easy
          </motion.h1>
          <div className="flex flex-col sm:flex-row gap-5">
            <motion.div  initial={{ x: -50, opacity : 0 }}
          whileInView={{ x: 0, opacity : 1 }}
          transition={{ duration: 2, type: "spring" }}>
              <Link
                to="/about-us"
                className="btn1 bg-troo-primary hover:bg-troo-white hover:text-troo-secondary"
              >
                LEARN MORE
              </Link>
            </motion.div>
            <motion.div initial={{ x: 50, opacity : 0 }}
          whileInView={{ x: 0, opacity : 1 }}
          transition={{ duration: 2, type: "spring" }}>
              <Link
                to="/our-services"
                className="btn1 bg-troo-secondary hover:bg-troo-white hover:text-troo-primary"
              >
                EXPLORE OUR SERVICES
              </Link>
            </motion.div>
          </div>
        </div>
        <div className="lg:absolute lg:-rotate-90 lg:-translate-y-1/2 lg:-left-60 lg:w-auto lg:top-[35%] bg-troo-white">
          <div className="flex p-6 items-center justify-center gap-5 ">
            <p className="relative before:absolute before:content-[''] before:w-44 before:h-[2px] before:top-2 before:-left-48 before:bg-troo-secondary">
              We Are on:
            </p>
            <div className="w-10 h-10 flex justify-center items-center bg-troo-secondary hover:bg-troo-primary transition-all ease-in-out duration-500 rounded-full text-troo-white">
              <FaFacebookF />
            </div>
            <div className="w-10 h-10 flex justify-center items-center bg-troo-secondary hover:bg-troo-primary transition-all ease-in-out duration-500 rounded-full text-troo-white">
              <FaInstagram />
            </div>
            <div className="w-10 h-10 flex justify-center items-center bg-troo-secondary hover:bg-troo-primary transition-all ease-in-out duration-500 rounded-full text-troo-white">
              <FaLinkedinIn />
            </div>
            <div className="w-10 h-10 flex justify-center items-center bg-troo-secondary hover:bg-troo-primary transition-all ease-in-out duration-500 rounded-full text-troo-white">
              <FaTwitter />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
