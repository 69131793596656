import React from "react";

const Input = ({ placeholder,isFaqPage, type, icon, ...rest }) => {
  return (
    <div className={`flex items-center w-full px-5 py-4 justify-between border  rounded ${isFaqPage ? "border-troo-white" : "border-troo-black border-opacity-20" } `}>
      <input
        type={type}
        placeholder={placeholder}
        {...rest}
        className={` bg-transparent outline-none w-full  ${isFaqPage ? "placeholder-troo-white" : "placeholder-troo-black"}
        }`}
      />
      <div className={`text-xl cursor-pointer  ${isFaqPage ? "text-troo-white " : " text-troo-black"}`}>{icon}</div>
    </div>
  );
};

export default Input;
