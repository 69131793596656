import React from "react";
import Heading from "./Heading";
import Input from "./Input";
import { BsCalendar, BsPerson, BsTelephone } from "react-icons/bs";

import { BiTime } from "react-icons/bi";

const Faqs = () => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2">
      <div className="py-12 lg:py-24 px-5 lg:px-32 bg-troo-secondary text-troo-white">
        <div className="flex flex-col gap-8">
          <div className="flex flex-col gap-5 ">
            <div>
              <Heading
                heading={
                  <>
                    Have an Event & Need Party Planner? <br />
                    Let’s Discuss With Us
                  </>
                }
                title={"What Our Clients Say"}
                textLight
              />
            </div>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text.
            </p>
          </div>
          <form className="w-full  flex flex-col items-start gap-14">
            <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-6">
              <Input isFaqPage placeholder="Full Name" icon={<BsPerson />} />
              <Input isFaqPage placeholder="Phone No." icon={<BsTelephone />} />
              <select
                className="select w-full bg-transparent border border-troo-white rounded px-5 py-4 "
              >
                <option disabled selected>
                  Subject
                </option>
                <option>Enquiry 1</option>
                <option>Enquiry 2</option>
                <option>Enquiry 3</option>
                <option>Enquiry 4</option>
              </select>
              <select
                className="select w-full bg-transparent border border-troo-white rounded px-5 py-4 "
              >
                <option disabled selected>
                  Subject
                </option>
                <option>Enquiry 1</option>
                <option>Enquiry 2</option>
                <option>Enquiry 3</option>
                <option>Enquiry 4</option>
              </select>
              <Input isFaqPage  placeholder="Date" icon={<BsCalendar />} />
              <Input isFaqPage placeholder="time" icon={<BiTime/>} />
            </div>
            <button className="btn1 w-full bg-troo-white text-troo-black hover:bg-troo-primary">
            Make Reservations
            </button>
          </form>
        </div>
      </div>
      <div className="bg-faq-bg bg-no-repeat bg-cover bg-center w-full h-full"></div>
    </div>
  );
};

export default Faqs;
