import React, { useEffect, useState } from "react";
import portfolioDetailsImg from "../images/portfolioDetailsImg.png";
import pDetails1 from "../images/Pdetails1.png";
import pDetails2 from "../images/Pdetails2.png";
import pDetails3 from "../images/Pdetails3.png";
import { useParams } from "react-router-dom";
import { PortfolioData } from "../Data/PortfolioData";
import { BsArrowRightCircle } from "react-icons/bs";
import RelatedBlogs from "./RelatedPortfolio";

const PortfolioDetails = () => {
  const {slug} = useParams()
  const [portfoliodata, setPortfolioData] = useState()
  useEffect(() => {
    const filteredData = PortfolioData.filter((data) => data.slug === slug)
    setPortfolioData(filteredData[0])
  }, [slug])

  return (
    <div className="pt-12 lg:pt-24 pb-12 lg:pb-72">
      <div className="main_container1">
        <div className="flex flex-col gap-6">
          <div>
            <img
              className="w-full"
              src={portfolioDetailsImg}
              alt="portfolioDetailsImg"
            />
            <div className="flex gap-5 justify-between flex-wrap py-5 border-b border-troo-secondary border-opacity-20">
              <div>
                <h3 className="font-Infont">Client</h3>
                <p>Robert Denialson</p>
              </div>
              <div>
                <h3 className="font-Infont">Location</h3>
                <p>7 Lake Street,London</p>
              </div>
              <div>
                <h3 className="font-Infont">Date</h3>
                <p>20 Apr 2023</p>
              </div>
              <div>
                <h3 className="font-Infont">Budget</h3>
                <p>$20000 - $50000</p>
              </div>
              <div>
                <h3 className="font-Infont">Category</h3>
                <p>Wedding, Wedding Destination</p>
              </div>
            </div>
          </div>
          <h2>{portfoliodata?.name || "Wedding of Alexa & Robert"}</h2>
          <p className="font-bold font-Infont">
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which don't look even slightly
            believable.
          </p>
          <p>
            There are many variations of passages of Lorem Randomised words
            which don't look even slightly believable. If you are going to use a
            passage of Lorem Ipsum, you need to be sure there isn't anything
            embarrassing hidden in the middle of text. All the Lorem Ipsum
            generators on the Internet tend to repeat predefined chunks as
            necessary, making this the first true generator on the Internet.
            Ipsum available, but the majority have suffered alteration in some
            form, by injected humour, or randomised words which don't look even
            slightly believable.
          </p>
          <h3 className="font-Infont">Overview</h3>
          <p>
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which don't look even slightly
            believable. If you are going to use a passage of Lorem Ipsum, you
            need to be sure there isn't anything embarrassing hidden in the
            middle of text. All the Lorem Ipsum generators on the Internet tend
            to repeat predefined.The majority have suffered alteration in some
            form.
          </p>
          <div className="flex w-full gap-6 flex-wrap">
            <div>
              <img className="w-full" src={pDetails1} alt="Pdetails1" />
            </div>
            <div>
              <img className="w-full" src={pDetails2} alt="Pdetails1" />
            </div>
            <div>
              <img className="w-full" src={pDetails3} alt="Pdetails1" />
            </div>
          </div>
          <h2>What We Provide</h2>
          <p>Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur notted adipisicing elit sed do eiusmod tempor incididunt ut labore et simply free Lorem ipsum dolor sit amet, consectetur notted adipisicing elit sed do eiusmod tempor incididunt ut labore et simply free text dolore magna aliqua lonm andhn.text dolore magna aliqua lonm andhn.notted adipisicing elit sed do eiusmod tempor incididunt.</p>
          <div className="flex flex-col gap-4">
              <p className="flex items-center gap-3">
                <BsArrowRightCircle />
                Personal, and affordable wedding planner
              </p>
              <p className="flex items-center gap-3">
                <BsArrowRightCircle />
                Because couples deserve beautiful wedding
              </p>
              <p className="flex items-center gap-3">
                <BsArrowRightCircle />A wedding that is True as you are!
              </p>
              <p className="flex items-center gap-3">
                <BsArrowRightCircle />
                Professional, affordable wedding planner
              </p>
            </div>
            <h2>Related Other Portfolio</h2>
         <RelatedBlogs currentSlug={slug}/>
        </div>
      </div>
    </div>
  );
};

export default PortfolioDetails;
