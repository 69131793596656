import service1 from "../images/servideIcon1.svg"
import service2 from "../images/servideIcon2.svg"
import service3 from "../images/servideIcon3.svg"
import service4 from "../images/servideIcon4.svg"
import service5 from "../images/servideIcon5.svg"
import service6 from "../images/servideIcon6.svg"

export const ServiceData = [
    {
        id : 1,
        img : service1,
        name : "Event Planner",
        slug: "event-planner",
        para : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text."
    },
    {
        id : 2,
        img : service2,
        name : "Dresses & Suits",
        slug : "dresses-and-suits",
        para : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text."
    },
    {
        id : 3,
        img : service3,
        name : "Destination Wedding",
        slug : "destination-wedding",
        para : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text."
    },
    {
        id : 4,
        img : service4,
        name : "Video Shooting",
        slug : "video-shooting",
        para : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text."
    },
    {
        id : 5,
        img : service5,
        name : "Photo Editing",
        slug : "photo-editing",
        para : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text."
    },   {
        id : 6,
        img : service6,
        name : "Catering Service",
        slug : "catering-service",
        para : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text."
    },
]