import React, { useEffect, useState } from "react";
import blogDetailsImg from "../images/blogDetailsImg.png";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaRegUser,
  FaTwitter,
} from "react-icons/fa";
import {
  BsArrowRightCircle,
  BsCalendar2Day,
  BsChatDots,
  BsChatSquareDots,
  BsPerson,
  BsTelephone,
} from "react-icons/bs";
import { BlogsData } from "../Data/BlogsData";
import { Link, useParams } from "react-router-dom";
import { HiArrowNarrowLeft, HiArrowNarrowRight } from "react-icons/hi";
import Input from "./Input";
import { IoMailOpenOutline } from "react-icons/io5";
import { FiEdit } from "react-icons/fi";

const BlogDetails = () => {
  const {slug} = useParams()
  const [blogdata, setblogdata] = useState()
  useEffect(() => {
    const filteredData = BlogsData.filter((data) => data.slug === slug)
    setblogdata(filteredData[0])
  }, [slug])

  return (
    <div className="pt-12 lg:pt-24 pb-12 lg:pb-72">
      <div className="main_container1">
        <div className="flex flex-col gap-5">
          <h2>{blogdata?.name ||"How to Keep Motivated as a Wedding Planner"}</h2>
          <div>
            <img className="w-full" src={blogDetailsImg} alt="blogDetailsImg" />
            <div className="flex flex-wrap gap-8 py-5 border-b border-troo-secondary border-opacity-20">
              <p className="flex items-center gap-2">
                <FaRegUser />
                <span className="font-bold">Posted By:</span> John Phillips
              </p>
              <p className="flex items-center gap-2">
                <BsCalendar2Day />
                <span className="font-bold">Posted By:</span> John Phillips
              </p>
              <p className="flex items-center gap-2">
                <BsChatSquareDots />
                <span className="font-bold">Posted By:</span> John Phillips
              </p>
            </div>
          </div>
          <p>
            There are many variations of passages of Lorem Randomised words
            which don't look even slightly believable. If you are going to use a
            passage of Lorem Ipsum, you need to be sure there isn't anything
            embarrassing hidden in the middle of text. All the Lorem Ipsum
            generators on the Internet tend to repeat predefined chunks as
            necessary, making this the first true generator on the Internet.
            Ipsum available, but the majority have suffered alteration in some
            form, by injected humour, or randomised words which don't look even
            slightly believable.
          </p>
          <p>
            Here are a few things that you can do to keep yourself motivated as
            a{" "}
            <span className="text-troo-secondary font-bold">
              wedding planner!
            </span>
          </p>
          <h2>Attend or host workshops</h2>
          <p>
            There are many variations of passages of Lorem Randomised words
            which don't look even slightly believable. If you are going to use a
            passage of Lorem Ipsum, you need to be sure there isn't anything
            embarrassing hidden in the middle of text. All the Lorem Ipsum
            generators on the Internet tend to repeat predefined chunks as
            necessary, making this the first true generator on the Internet.
            Ipsum available, but the majority have suffered alteration in some
            form,
          </p>
          <p>
            There are many variations of passages of Lorem Randomised words
            which don't look even slightly believable. If you are going to use a
            passage of Lorem Ipsum, you need to be sure there isn't anything
            embarrassing hidden in the middle of text. All the Lorem Ipsum
            generators on the Internet tend.
          </p>
          <h2>Find new trends</h2>
          <p>
            There are many variations of passages of Lorem Randomised words
            which don't look even slightly believable. If you are going to use a
            passage of Lorem Ipsum, you need to be sure there isn't anything
            embarrassing hidden in the middle of text. All the Lorem Ipsum
            generators on the Internet tend to repeat predefined chunks as
            necessary, making this the first true generator on the Internet.
            Ipsum available, but the majority have suffered alteration in some
            form,
          </p>
          <h2>Take advance bookings</h2>
          <p>
            There are many variations of passages of Lorem Randomised words
            which don't look even slightly believable. If you are going to use a
            passage of Lorem Ipsum, you need to be sure there isn't anything
            embarrassing hidden in the middle of text. All the Lorem Ipsum
            generators on the Internet tend to repeat predefined chunks as
            necessary, making this the first true generator on the Internet.
            Ipsum available, but the majority have suffered alteration in some
            form,
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {BlogsData.slice(0, 3).map((e) => (
              <div className="" key={e}>
                <img src={e.img} alt={e.name} />
              </div>
            ))}
          </div>
          <h2>Benifits of Wedding Planner Business</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet,
            consectetur notted adipisicing elit sed do eiusmod tempor incididunt
            ut labore et simply free Lorem ipsum dolor sit amet, consectetur
            notted adipisicing elit sed do eiusmod tempor incididunt ut labore
            et simply free text dolore magna aliqua lonm andhn.text dolore magna
            aliqua lonm andhn.notted adipisicing elit sed do eiusmod tempor
            incididunt.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
            <div className="flex flex-col gap-4">
              <p className="flex items-center gap-3">
                <BsArrowRightCircle />
                Personal, and affordable wedding planner
              </p>
              <p className="flex items-center gap-3">
                <BsArrowRightCircle />
                Because couples deserve beautiful wedding
              </p>
              <p className="flex items-center gap-3">
                <BsArrowRightCircle />A wedding that is True as you are!
              </p>
              <p className="flex items-center gap-3">
                <BsArrowRightCircle />
                Professional, affordable wedding planner
              </p>
            </div>
            <div className="flex flex-col gap-4">
              <p className="flex items-center gap-3">
                <BsArrowRightCircle />
                Personal, and affordable wedding planner
              </p>
              <p className="flex items-center gap-3">
                <BsArrowRightCircle />
                Because couples deserve beautiful wedding
              </p>
              <p className="flex items-center gap-3">
                <BsArrowRightCircle />A wedding that is True as you are!
              </p>
              <p className="flex items-center gap-3">
                <BsArrowRightCircle />
                Professional, affordable wedding planner
              </p>
            </div>
            <div className="flex flex-col gap-4">
              <p className="flex items-center gap-3">
                <BsArrowRightCircle />
                Personal, and affordable wedding planner
              </p>
              <p className="flex items-center gap-3">
                <BsArrowRightCircle />
                Because couples deserve beautiful wedding
              </p>
              <p className="flex items-center gap-3">
                <BsArrowRightCircle />A wedding that is True as you are!
              </p>
              <p className="flex items-center gap-3">
                <BsArrowRightCircle />
                Professional, affordable wedding planner
              </p>
            </div>
          </div>
          <div className="flex justify-between py-2 border-y border-troo-secondary border-opacity-20">
            <div className="flex gap-2 items-center">
              <p>Tags:</p>
              <Link>Wedding</Link>
              <Link>Planner</Link>
              <Link>Business</Link>
              <Link>Couple</Link>
            </div>
            <div className="flex items-center gap-3">
              <p>Share This Article:</p>
              <div className="w-8 h-8 flex justify-center items-center bg-troo-white hover:bg-troo-primary transition-all ease-in-out duration-500 rounded-full text-troo-black hover:text-troo-white">
                <FaFacebookF />
              </div>
              <div className="w-8 h-8 flex justify-center items-center bg-troo-white hover:bg-troo-primary transition-all ease-in-out duration-500 rounded-full text-troo-black hover:text-troo-white">
                <FaInstagram />
              </div>
              <div className="w-8 h-8 flex justify-center items-center bg-troo-white hover:bg-troo-primary transition-all ease-in-out duration-500 rounded-full text-troo-black hover:text-troo-white">
                <FaLinkedinIn />
              </div>
              <div className="w-8 h-8 flex justify-center items-center bg-troo-white hover:bg-troo-primary transition-all ease-in-out duration-500 rounded-full text-troo-black hover:text-troo-white">
                <FaTwitter />
              </div>
            </div>
          </div>
          <div className="flex justify-between ">
            <p className="flex gap-3 items-center">
              <HiArrowNarrowLeft />
              Prev Article
            </p>
            <p className="flex gap-3 items-center">
              Next Article
              <HiArrowNarrowRight />
            </p>
          </div>
          <div className="mt-8 flex flex-col gap-10">
            <h3 className="font-Infont">Post Comment</h3>
          <form className="w-full  flex flex-col items-start gap-14">
              <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-6">
                <Input placeholder="Full Name" icon={<BsPerson/>} />
                <Input
                  placeholder="Email Adress"
                  icon={<IoMailOpenOutline />}
                />
                <Input placeholder="Phone No." icon={<BsTelephone />} />
                <Input placeholder="Company Name" icon={<FiEdit />} />
                <div className="flex md:col-span-2 items-start w-full rounded px-5 py-3 justify-between border border-troo-black border-opacity-20">
                  <textarea
                    rows={5}
                    placeholder="Message"
                    className="bg-transparent outline-none w-full"
                  />
                  <div className="text-troo-light text-lg">
                    <BsChatDots />
                  </div>
                </div>
              </div>
              <button className="btn1 bg-troo-secondary text-troo-white hover:bg-troo-primary">
              Post Comment
              </button>
            </form>
          </div>

        </div>
      </div>
    </div>
  );
};

export default BlogDetails;
