import React, { useEffect, useState } from "react";
import {
  Link,
  useLocation,
  useParams,
} from "react-router-dom";
import { BlogsData } from "../Data/BlogsData";
import { ServiceData } from "../Data/ServiceData";
import { PortfolioData } from "../Data/PortfolioData";

import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
} from "react-icons/fa";

const BannerGlobal = ({title}) => {
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const location = useLocation();
  const pathName = location.pathname;
  const { slug } = useParams();
  const [serviceDetails, setServiceDetails] = useState();
  const allDataArr = [
    ...ServiceData,
    ...BlogsData,
    ...PortfolioData,

  ];
  useEffect(() => {
    const filteredData = allDataArr.filter((data) => data.slug === slug);
    setServiceDetails(filteredData[0]);
  }, [slug]);
  console.log("serviceDetails" ,serviceDetails);

  useEffect(() => {
    const pathnameParts = location.pathname.split("/").filter((p) => !!p);
    const breadcrumbs = pathnameParts.map((part, i) => ({
      title: part,
      path: `/${pathnameParts.slice(0, i + 1).join("/")}`,
    }));
    setBreadcrumbs(breadcrumbs);
  }, [location]);
  return (
    <div className="bg-banner-global bg-cover bg-no-repeat bg-center  w-full h-full relative z-[1] before:content-[''] before:absolute before:w-full before:h-full before:bg-troo-black before:opacity-70 before:z-[-1] lg:ml-24">
      <div className="main_container relative z-10 py-24 lg:py-44">
        <div className="flex flex-col justify-center items-center">
          <div className="py-3 px-5 md:px-8 rounded shadow-sm">
            <h2 className="text-troo-white text-center max-w-[700px]">
              {serviceDetails?.name || title}
            </h2>
          </div>
          <ul className="flex flex-wrap gap-1 justify-center px-5 py-3 bg-troo-white absolute bottom-1 rounded ">
            <Link to="/">Home</Link>
            <span>|</span>
            {breadcrumbs.map((breadcrumb, index) => (
              <React.Fragment key={index}>
                <li
                  className={`capitalize text-troo-black ${
                    index === breadcrumbs.length - 1
                      ? "text-troo-secondary font-bold"
                      : ""
                  }`}
                >
                  <Link
                    to={breadcrumb.path}
                    className={`${
                      breadcrumb.path === pathName
                        ? "text-troo-secondary font-bold"
                        : ""
                    } ${breadcrumb.path === "/faq" ? "uppercase" : ""}`}
                  >
                    {breadcrumb.title.replace(/-/g, " ")}
                  </Link>
                </li>
                {index !== breadcrumbs.length - 1 && <span>|</span>}
              </React.Fragment>
            ))}
          </ul>
        </div>
      </div>
      <div className="lg:absolute lg:-rotate-90 lg:-translate-y-1/2 lg:-left-60 lg:w-auto lg:top-[45%] bg-troo-white">
        <div className="flex p-6 items-center justify-center gap-5 ">
          <p className="relative ">We Are on:</p>
          <div className="w-10 h-10 flex justify-center items-center bg-troo-secondary hover:bg-troo-primary transition-all ease-in-out duration-500 rounded-full text-troo-white">
            <FaFacebookF />
          </div>
          <div className="w-10 h-10 flex justify-center items-center bg-troo-secondary hover:bg-troo-primary transition-all ease-in-out duration-500 rounded-full text-troo-white">
            <FaInstagram />
          </div>
          <div className="w-10 h-10 flex justify-center items-center bg-troo-secondary hover:bg-troo-primary transition-all ease-in-out duration-500 rounded-full text-troo-white">
            <FaLinkedinIn />
          </div>
          <div className="w-10 h-10 flex justify-center items-center bg-troo-secondary hover:bg-troo-primary transition-all ease-in-out duration-500 rounded-full text-troo-white">
            <FaTwitter />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerGlobal;
