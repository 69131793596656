import { FaStar } from 'react-icons/fa';
import client1 from "../images/client1.png"
import client2 from "../images/client2.png"
import client3 from "../images/client3.png"
import client4 from "../images/client4.png"
import { FiStar } from 'react-icons/fi';



export const FeedbackData = [
    {
        id : 1,
        img : client1,
        name : "Anna Jay",
        desg : "Wedding Destination",
        para : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.",
        rating : <><FaStar/><FaStar/><FaStar/><FaStar/><FiStar/></>
    },
    {
        id : 2,
        img : client2,
        name : "Bob Muller",
        desg : "Photography",
        para : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.",
        rating : <><FaStar/><FaStar/><FaStar/><FaStar/><FiStar/></>
    },
    {
        id : 3,
        img : client3,
        name : "Angelo Morar",
        desg : "Catering Services",
        para : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.",
        rating : <><FaStar/><FaStar/><FaStar/><FaStar/><FiStar/></>
    },
    {
        id : 4,
        img : client4,
        name : "Jennifer Murray",
        desg : "Bridal Mack-Up",
        para : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.",
        rating : <><FaStar/><FaStar/><FaStar/><FaStar/><FiStar/></>
    },

]