import React from "react";
import { PricingData } from "../Data/PricingData";
import { BsCheckLg } from "react-icons/bs";
import { Link } from "react-router-dom";

const PricingCard = () => {
  return (
    <div className="pt-12 lg:pt-24 pb-12 lg:pb-72">
      <div className="main_container1">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {PricingData.map((e, i) => (
            <div key={i} className={`p-5 shadow-lg text-troo-white hover:bg-troo-black transition-all duration-500 ease-in-out ${i % 2 === 0 ? "bg-troo-secondary" : "bg-troo-primary"}`}>
             <div className="flex  flex-col gap-4  p-8 relative before:content-[''] before:absolute before:w-full before:h-full before:border before:border-dashed before:border-troo-white before:transition-all before:duration-500 before:ease-in-out before:top-0 before:left-0 before:scale-0 hover:before:scale-100">
             <h3 className="font-Infont">{e.name}</h3>
              <div>
                <h2>{e.price}</h2>
                <h4>Per month</h4>
              </div>
              <p className="pb-2 border-b border-troo-tertiary border-opacity-20">{e.para}</p>
              <div className="flex flex-col gap-3 py-5">
                  {e.highlights.map((ele, ind) => (
                    <p className={`${ele.className} `} key={ind}>
                      <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-white text-troo-black rounded-[50%]" />
                      {ele.heading}
                    </p>
                  ))}
                </div>
                <Link className="btn1 mt-5 bg-troo-white text-troo-black group-hover:bg-troo-primary ">
                  Choose This Plan
                </Link>
             </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PricingCard;
