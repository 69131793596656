import React, { useEffect, useState } from "react";
import { ServiceData } from "../Data/ServiceData";
import { Link } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";

const RelatedService = ({ currentSlug }) => {
  const [relatedService, setRelatedService] = useState([]);
  useEffect(() => {
    const filterServiceData = ServiceData.filter(
      (data) => data.slug !== currentSlug
    );
    setRelatedService(filterServiceData);
    window.scrollTo(0, 0);
  }, [currentSlug]);
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      {relatedService.slice(0, 3).map((e, i) => (
        <div>
          <Link
            to={`/our-services/${e.slug}`}
            key={i}
            className="bg-troo-white flex flex-col gap-4 p-7 group"
          >
            <div>
              <div className="bg-troo-secondary rounded-full p-3 w-16 h-16 flex justify-center items-center group-hover:bg-troo-primary transition-all ease-in-out duration-500">
                <img className="w-full" src={e.img} alt={e.name} />
              </div>
            </div>
            <h3 className="font-Infont group-hover:text-troo-secondary transition-all duration-500 ease-in-out ">
              {e.name}
            </h3>
            <p>{e.para}</p>
            <Link>
              <p className="flex gap-3 items-center">
                Read More <BsArrowRight />
              </p>
            </Link>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default RelatedService;
