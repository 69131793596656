import React from 'react'
import Layout from '../Components/Layout'
import Banner from '../Components/Banner'
import Abouts from '../Components/Abouts'
import Services from '../Components/Services'
import Portfolio from '../Components/Portfolio'
import VideoSection from '../Components/VideoSection'
import Feedbacks from '../Components/Feedbacks'
import Teams from '../Components/Teams'
import Faqs from '../Components/Faqs'
import Blogs from '../Components/Blogs'
import useDocumentTitle from '../Hooks/PageTitle'

const Home = () => {
  useDocumentTitle("TRoo Wedding Planner - React Js Template")
  return (
    <Layout>
        <Banner/>
        <Abouts/>
        <Services/>
        <Portfolio/>
        <VideoSection/>
        <Feedbacks/>
        <Teams/>
        <Faqs/>
        <Blogs/>
    </Layout>
  )
}

export default Home