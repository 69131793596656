import React, { useEffect, useState } from "react";
import Heading from "./Heading";
import { BlogsData } from "../Data/BlogsData";
import { Link } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import { motion } from "framer-motion";
const Blogs = ({ isBlogPage }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    if (isBlogPage) {
      setData(BlogsData);
    } else {
      setData(BlogsData.slice(0, 3));
    }
  }, []);
  return (
    <div className="bg-troo-tertiary pt-12 lg:pt-24 pb-12 lg:pb-72">
      <div className="main_container1">
        {!isBlogPage && (
          <div className="mb-12">
            <Heading
              heading={
                <>
                  Most Popular News & Top Blogs
                  <br />
                  About Wedding Planing
                </>
              }
              title={"Articles About Weddings"}
              isCenter
            />
          </div>
        )}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {data.map((e, i) => (
            <motion.div initial={{ x: -50, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ type: "spring", stiffness: "100", delay:0.5 * i }}>
              <Link key={i} to={`/our-blogs/${e.slug}`} className="group flex flex-col items-center">
                <div className="relative before:content-[''] before:bg-troo-black before:bg-opacity-50 before:absolute before:top-0 before:w-full before:h-0 group-hover:before:h-full before:transition-all before:ease-in-out before:duration-300 flex justify-center items-center">
                  <img src={e.img} alt={e.name} />
                  <div className="absolute text-7xl text-troo-white scale-0 group-hover:scale-100 transition-all duration-700 ease-in-out">
                    +
                  </div>
                </div>
                <div className="w-[90%] shadow  z-20 -mt-8 p-8 bg-troo-white flex flex-col items-center text-center gap-3">
                  <p>{e.date}</p>
                  <h3 className="font-Infont">{e.name}</h3>
                  <Link>
                    <p className="flex gap-3 items-center font-Infont font-bold text-troo-secondary">
                      Read More <BsArrowRight />
                    </p>
                  </Link>
                </div>
              </Link>
            </motion.div>
          ))}
        </div>
        {!isBlogPage && (
          <div className="text-center mt-12">
            <Link to="/our-blogs" className="btn1 bg-troo-secondary hover:bg-troo-primary text-troo-white">
              View All Blogs
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default Blogs;
