import React, { useEffect, useState } from "react";
import Heading from "./Heading";
import { PortfolioData } from "../Data/PortfolioData";
import { Link } from "react-router-dom";
import Highlight1 from "./Highlight1";
import { motion } from "framer-motion";

const Portfolio = ({ isPortfolioPage }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    if (isPortfolioPage) {
      setData(PortfolioData);
    } else {
      setData(PortfolioData.slice(0, 5));
    }
  }, []);
  return (
    <div className="bg-portfolio-bg bg-no-repeat w-full py-12 lg:py-24">
      <div className="main_container1">
        {!isPortfolioPage && (
          <motion.div
            initial={{ y: -50, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ duration: 2, type: "spring" }}
            className="mb-12"
          >
            <Heading
              heading={
                <>
                  Every love story is beautiful,
                  <br />
                  but yours should be unique
                </>
              }
              title={"Our Amazing Work"}
              isCenter
            />
          </motion.div>
        )}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-7">
          {data.map((e, i) => (
            <motion.div
              initial={{ y: -50, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{ type: "spring", stiffness: "100", delay: 0.5 * i }}
              className={`${
                i === 1 && "row-span-2"
              } relative group shadow-lg overflow-hidden transition-all duration-200 ease-linear`}
              key={i}
            >
              <img
                src={e.img}
                alt={e.name}
                className="w-full h-full object-cover"
              />
              <Link
                to={`/our-portfolio/${e.slug}`}
                className="group-hover:block transition-all duration-200 ease-linear hidden "
              >
                <div className="absolute left-0 w-full h-full top-0 bg-troo-black bg-opacity-60 flex flex-col items-start justify-end">
                  <motion.p
                    initial={{ x: -100, opacity: 0 }}
                    whileInView={{ x: 0, opacity: 1 }}
                    transition={{
                      type: "spring",
                      stiffness: "100",
                      delay: 0.5 * 0,
                    }}
                    className="p-3 bg-troo-primary text-troo-white rounded-e"
                  >
                    {e.sub}
                  </motion.p>
                  <motion.h3
                    initial={{ x: -100, opacity: 0 }}
                    whileInView={{ x: 0, opacity: 1 }}
                    transition={{
                      type: "spring",
                      stiffness: "100",
                      delay: 0.5 * 0.5,
                    }}
                    className="p-5 bg-troo-white font-Infont text-troo-primary rounded-e"
                  >
                    {e.name}
                  </motion.h3>
                </div>
              </Link>
            </motion.div>
          ))}
        </div>
        <div className="text-center mt-12">
          <Link
            to="/our-portfolio"
            className="btn1 bg-troo-primary text-troo-white hover:bg-troo-secondary"
          >
            View All Portfolio
          </Link>
        </div>
        {!isPortfolioPage && (
          <div className="mt-12">
            <Highlight1 />
          </div>
        )}
      </div>
    </div>
  );
};

export default Portfolio;
