import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Routes/Home";
import AboutPage from "./Routes/AboutPage";
import ServicePage from "./Routes/Service/ServicePage";
import ServiceDetailsPage from "./Routes/Service/ServiceDetailsPage";
import ProjectPage from "./Routes/Project/ProjectPage";
import ProjectDetailsPage from "./Routes/Project/ProjectDetailsPage";
import TeamsPage from "./Routes/Pages/TeamsPage";
import TestimonialPage from "./Routes/Pages/TestimonialPage";
import PricingCardPage from "./Routes/Pages/PricingCardPage";
import ErrorPage from "./Routes/Pages/ErrorPage";
import ComingSoonPage from "./Routes/Pages/ComingSoonPage";
import BlogsPage from "./Routes/Blogs/BlogsPage";
import BlogDetailsPage from "./Routes/Blogs/BlogDetailsPage";
import ContactsPage from "./Routes/ContactsPage";

function App() {
  return (
    <div className="App">
     <BrowserRouter>
     <Routes>
      <Route path="/" element={<Home/>} />
      <Route path="/about-us" element={<AboutPage/>} />
      <Route path="/our-services" element={<ServicePage/>} />
      <Route path="/our-services/:slug" element={<ServiceDetailsPage/>} />
      <Route path="/our-portfolio" element={<ProjectPage/>} />
      <Route path="/our-portfolio/:slug" element={<ProjectDetailsPage/>} />
      <Route path="/our-team" element={<TeamsPage/>} />
      <Route path="/testimonial" element={<TestimonialPage/>} />
      <Route path="/pricing-plan" element={<PricingCardPage/>} />
      <Route path="/error-404" element={<ErrorPage/>} />
      <Route path="/coming-soon" element={<ComingSoonPage/>} />
      <Route path="/our-blogs" element={<BlogsPage/>} />
      <Route path="/our-blogs/:slug" element={<BlogDetailsPage/>} />
      <Route path="/contact-us" element={<ContactsPage/>} />
     </Routes>
     </BrowserRouter>
    </div>
  );
}

export default App;
